export const mapMenuitems: any[] =    [ 
    // { 
    //     menuSectionDescription: 'STATISTICS',
    //     roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
    //     menuSection: 'statistics', 
    //     icon: 'finance', 
    //     sectionTitle: 'sidebarmenu-section-statistics',  
    //     subSections: [
    //         {
    //             subSection: 'efficiency',
    //             subsectionDescription: 'EFFICIENCY',
    //             roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
    //             href: null,
    //             routerLink: ['statistics', 'enginefficiency'], 
    //             icon: 'monitoring',                  
    //             subSectionTitle: 'sidebarmenu-section-efficiency' 
    //         },
    //         {
    //             subSection: 'telemetry',
    //             subsectionDescription: 'TELEMETRY',
    //             roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
    //             href: null,
    //             routerLink: ['statistics', 'telemetry'], 
    //             icon: 'monitoring',                  
    //             subSectionTitle: 'sidebarmenu-section-telemetry' 
    //         },
    //         // {
    //         //     subSection: 'maintenance',
    //         //     subsectionDescription: 'MAINTENANCE',
    //         //     roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
    //         //     href: null,
    //         //     routerLink: ['statistics', 'maintenance'], 
    //         //     icon: 'monitoring',                  
    //         //     subSectionTitle: 'sidebarmenu-section-maintenance' 
    //         // },
    //         // {
    //         //     subSection: 'green',
    //         //     subsectionDescription: 'GREEN EFFICIENCY',
    //         //     roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
    //         //     href: null,
    //         //     routerLink: ['statistics', 'green'], 
    //         //     icon: 'monitoring',                  
    //         //     subSectionTitle: 'sidebarmenu-section-green-efficiency' 
    //         // }
    //     ]
    // },
    { 
        menuSectionDescription: 'VEHICLES',
        roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER', 'AC_INSTALLER'  ], 
        menuSection: 'vehicles', 
        icon: 'agriculture', 
        sectionTitle: 'sidebarmenu-section-vehicles',  
        subSections: [
            {
                subSection: 'fleet',
                subsectionDescription: 'FLEET',
                roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER' ], 
                href: null,
                routerLink: ['vehicles', 'fleet'], 
                icon: 'map',                  
                subSectionTitle: 'sidebarmenu-section-fleet' 
            },
            {
                subSection: 'vehicles',
                subsectionDescription: 'VEHICLES',
                roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER', 'AC_INSTALLER' ], 
                href: null,
                routerLink: ['vehicles', 'vehiclelist'], 
                icon: 'agriculture',                  
                subSectionTitle: 'sidebarmenu-section-vehicles' 
            }            
        ]
    },
    { 
        menuSectionDescription: 'USERS',
        roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER'  ], 
        menuSection: 'users', 
        icon: 'group', 
        sectionTitle: 'sidebarmenu-section-users',  
        subSections: [
            {
                subSection: 'customers',
                subsectionDescription: 'USERS',
                roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER' ], 
                href: null,
                routerLink: ['users', 'customers'], 
                icon: 'patient_list',                  
                subSectionTitle: 'sidebarmenu-section-customers' 
            }, 
            // {
            //     subSection: 'organizations',
            //     subsectionDescription: 'ORGANIZZAZIONI',
            //     roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER'], 
            //     href: null,
            //     routerLink: ['users', 'organizations'], 
            //     icon: 'reduce_capacity',                  
            //     subSectionTitle: 'sidebarmenu-section-organizations' 
            // },
            {
                subSection: 'dealers',
                subsectionDescription: 'CONCESSIONARI',
                roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER'], 
                href: null,
                routerLink: ['users', 'dealers'], 
                icon: 'emoji_transportation',                  
                subSectionTitle: 'sidebarmenu-section-dealers' 
            }
        ]
    },
    { 
      menuSectionDescription: 'CONTROL PANEL',
      roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER', 'AC_INSTALLER', 'AC_ACTIVATOR' ], 
      menuSection: 'controlpanel', 
      icon: 'empty_dashboard', 
      sectionTitle: 'sidebarmenu-section-controlpanel',  
      subSections: [
        {
            subSection: 'models',
            subsectionDescription: 'VEHICLE MODELS',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER', 'AC_USER', 'AC_INSTALLER', 'AC_ACTIVATOR' ], 
            href: null,
            routerLink: ['controlpanel','models', 'modelstable'], 
            icon: 'family_history',                  
            subSectionTitle: 'sidebarmenu-section-models' 
        }, 
        {
          subSection: 'devices',
          subsectionDescription: 'DEVICEs',
          roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER' ], 
          href: null,
          routerLink: ['controlpanel','devices', 'list'], 
          icon: 'router',                  
          subSectionTitle: 'sidebarmenu-section-devices' 
        },
        {
            subSection: 'customerwizard',
            subsectionDescription: 'WIZARD CREAZIONE CLIENTE',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER' ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'customer'], 
            icon: 'settings_account_box',                  
            subSectionTitle: 'sidebarmenu-section-customer-wizard'  
        },
        {
            subSection: 'userwizard',
            subsectionDescription: 'WIZARD CREAZIONE UTENTE',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER'  ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'user'], 
            icon: 'settings_account_box',                  
            subSectionTitle: 'sidebarmenu-section-user-wizard'  
        },
        {
            subSection: 'vehiclewizard',
            subsectionDescription: 'WIZARD CREAZIONE VEICOLO',
            roles: ['SUPERADMIN', 'AC_DEVELOPER'], 
            href: null,
            routerLink: ['controlpanel','wizard', 'vehicle'], 
            icon: 'agriculture',                  
            subSectionTitle: 'sidebarmenu-section-vehicle-wizard'  
        },
        {
            subSection: 'assignvehiclewizard',
            subsectionDescription: 'WIZARD ASSEGNAZIONE VEICOLO',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_ACTIVATOR'  ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'assignvehicle'], 
            icon: 'emoji_transportation',                  
            subSectionTitle: 'sidebarmenu-section-assign-wizard'  
        },
        {
            subSection: 'uploadwizard',
            subsectionDescription: 'WIZARD UPLOAD DOCUMENTAZIONE',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER' ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'uploaddoc'], 
            icon: 'cloud_upload',                  
            subSectionTitle: 'sidebarmenu-section-upload-documents-wizard'  
        },
        {
            subSection: 'areawizard',
            subsectionDescription: 'WIZARD CREAZIONE AREA',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_OWNER' ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'areas'], 
            icon: 'map',                  
            subSectionTitle: 'sidebarmenu-section-wizard-area'  
        },
        {
            subSection: 'installation',
            subsectionDescription: 'INSTALLATION WIZARD',
            roles: ['SUPERADMIN', 'AC_DEVELOPER',  'ADMIN',  'AC_DEALER', 'AC_RESELLER', 'AC_INSTALLER' ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'deviceinstallation'], 
            linkActive: '/controlpanel/wizard/deviceinstallation',
            icon: 'build',                  
            subSectionTitle: 'sidebarmenu-section-wizard-device-installation' 
        },
        {
            subSection: 'activation',
            subsectionDescription: 'ACTIVATION WIZARD',
            roles: ['SUPERADMIN', 'AC_DEVELOPER', 'AC_ACTIVATOR' ], 
            href: null,
            routerLink: ['controlpanel','wizard', 'deviceactivation'],  
            linkActive: '/controlpanel/wizard/deviceactivation',
            icon: 'bolt',                  
            subSectionTitle: 'sidebarmenu-section-wizard-device-activation' 
          },
          {
            subSection: 'translations',
            subsectionDescription: 'GESTIONE TRADUZIONI',
            roles: ['SUPERADMIN', 'AC_DEVELOPER' ], 
            href: null,
            routerLink: ['controlpanel','lang'], 
            icon: 'translate',                  
            subSectionTitle: 'sidebarmenu-section-language'  
          }
        
      ]
    } 
];